import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import { graphql, navigate } from 'gatsby';

import WallcoveringsCollection from '../components/Wallcoverings/WallcoveringsCollection';
import Seo from '../components/Seo';

const WallcoveringsCollectionPage = ({
  data: { collection, site },
  pageContext: { collectionSlug, noIndex },
  location,
}) => {
  useEffect(() => {
    if (!collection) {
      navigate('/');
    }
  }, [collection]);
  if (!collection) return null;

  const seoTitle = collection?.seo?.title || collection?.title;
  const seoDescription =
    collection?.seo?.description || collection?.description;

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        canonical={`${site.siteMetadata.siteUrl}${location.pathname}`}
        image={collection?.image?.originalSrc}
        titleTemplate="%s | Backdrop x Schumacher"
        noIndex={noIndex}
      />
      <Router basepath={`/collections/${collectionSlug}`}>
        <WallcoveringsCollection
          path="*activeHandle"
          collection={collection}
          routeBase={`/collections/${collectionSlug}`}
        />
      </Router>
    </>
  );
};

WallcoveringsCollectionPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object,
  filters: PropTypes.array,
};

export default WallcoveringsCollectionPage;

export const query = graphql`
  query WallcoveringsCollectionPage($collectionSlug: String!) {
    collection: shopifyCollection(handle: { eq: $collectionSlug }) {
      title
      description
      descriptionHtml
      seo {
        description
        title
      }
      image {
        originalSrc
      }
      products {
        id
        shopifyId
        title
        tags
        handle
        designer: metafield(key: "name", namespace: "designer") {
          value
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        productType
        media {
          ... on ShopifyMediaImage {
            image {
              originalSrc
            }
          }
        }
        featuredImage {
          originalSrc
        }
        description
        variants {
          id
          shopifyId
          title
          selectedOptions {
            name
            value
          }
        }
      }
      shopifyId
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
